<template>
    <div>
            <ValidationObserver ref="formModalValidate">
                    <b-row class="mt-2">
                        <b-col cols="12" md="12">
                            <b-form-group class="" :label="$t('name')">
                                <ValidationProvider
                                    name="name"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group class="mb-3" >
                                        <b-form-input
                                            v-model="formData.name"
                                            :class="errors[0] ? 'is-invalid' : ''"
                                        />
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                        class="mb-2"
                                    />
                                </ValidationProvider>
                            </b-form-group>
                            <ValidationProvider name="contents"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('contents')">
                                    <b-form-input v-model="formData.contents"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="outputs"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('outputs')">
                                    <b-form-input v-model="formData.outputs"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="min_pass_count"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('min_pass_count')">
                                    <b-form-input type="number" v-model="formData.min_pass_count"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="d-flex justify-content-center mt-3 mb-3">
                                <b-button variant="primary"
                                          @click="createForm"
                                          :disabled="formLoading">
                                    {{ $t('save') }}
                                </b-button>
                            </div>
                        </b-col>

                    </b-row>
            </ValidationObserver>
    </div>
</template>

<script>
// Components


// Services
import CertificateService from "@/services/CertificateService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';


export default {
    components: {


        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            type:"coop",
            formData: {
                // Tab 1
            },
            formLoading: false
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                this.formData.type = this.type
                let formData = {
                    ...this.formData
                }

                CertificateService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    })
            }
            else {
                let errors = this.$refs.formModalValidate.errors;

            }
        }
    }
}
</script>

